import linkedin from '../assets/linkedin_logo.png'
import yt_logo from '../assets/yt_logo.png'
import tw_logo from '../assets/tw_logo_sm.png'

export const socials = [
    { url: 'https://twitter.com/BirkenbihlC', label: 'Twitter', icon: tw_logo },
    { url: 'https://www.linkedin.com/in/colin-birkenbihl-575a35145/', label: 'LinkedIn', icon: linkedin },
    { url: 'https://www.youtube.com/channel/UCvGxwaTPiBMgDwqJTZAS9iw', label: 'Youtube', icon: yt_logo },
  ]

export const scholar = 'https://scholar.google.com/citations?user=WCNSmkkAAAAJ&hl'
export const orcid = 'https://orcid.org/0000-0002-7212-7700'