import React from 'react'

import rss_logo from '../assets/rss_logo.png'
import letter_logo from '../assets/letter_logo.png'

import { socials } from '../data/links'

const madeWithLinks = [
  { url: 'https://birkenbihl.substack.com', label: 'Newsletter', icon: letter_logo },
  { url: 'https://www.birkenbihl-lab.com/rss.xml', label: 'RSS', icon: rss_logo },
]

export const Footer = () => {
  return (
    <footer className="footer">
      <section>
        <nav>
          {socials.map((link) => (
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              key={link.url}
            >
              <span>{link.label}</span>
              <img src={link.icon} alt={link.label} />
            </a>
          ))}
        </nav>
        <nav>
          {madeWithLinks.map((link) => (
            <a
              href={link.url}
              title={link.label}
              target="_blank"
              rel="noopener noreferrer"
              key={link.url}
            >
              <span>{link.label}</span>
              <img src={link.icon} alt={link.label} />
            </a>
          ))}
        </nav>
      </section>
    </footer>
  )
}
